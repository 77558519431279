<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-menu
        v-model="menu"
        lazy
        close-on-content-click
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          min="2023-10"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search entity"
        clearable
      />
      <v-spacer />
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            :disabled="!enableExport"
            :loading="!enableExport"
            color="green"
            v-on="on"
            @click="exportReport"
          >
            <v-icon dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          Export Report
        </span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagination.sync="pagination"
      :total-items="totalItems"
      :rows-per-page-items="[10, 25, 50, 100]"
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <tr>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.action }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.tarikh }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealerId }}
          </td>
          <td class="text-xs-left">
            {{ props.item.amount }}
          </td>
          <td class="text-xs-left">
            {{ props.item.plan }}
          </td>
        </tr>
      </template>
      <template slot="no-data">
        <v-alert
          :value="true"
          :type="error ? 'error' : 'info'"
        >
          {{ noDataText }}
        </v-alert>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import zipcelx from 'zipcelx'

const reportTitle = 'October Free Sim Campaign Reimbursement'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2014, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Reimbursement Excerpt', disabled: false, to: '/management/reimbursement_view',
        },
        {
          text: 'October Free Sim Reimbursement', disabled: true,
        },
      ],
      enableExport: true,
      error: null,
      exportedReport: [],
      exportReportHeader:
            [
              {
                value: 'Date',
                type: 'string',
              },
              {
                value: 'Action',
                type: 'string',
              },
              {
                value: 'Entity',
                type: 'string',
              },
              {
                value: 'Activation Date',
                type: 'string',
              },
              {
                value: 'Dealer',
                type: 'string',
              },
              {
                value: 'Amount',
                type: 'string',
              },
              {
                value: 'Plan',
                type: 'string',
              },
            ],
      headers: [
        {
          align: 'left',
          sortable: false,
          text: 'Date',
          value: 'entry',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Entity',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Activation Date',
          value: 'tarikh',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Dealer',
          value: 'dealerId',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Amount',
          value: 'amount',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Plan',
          value: 'Plan',
        },
      ],
      items: [],
      loading: false,
      maxDate: '',
      menu: false,
      monthOf: null,
      noResultText: 'No data that fits that criteria.',
      pagination: null,
      search: '',
      searchEntity: '',
      searchId: null,
      source: null,
      title: reportTitle,
      totalItems: 0,
    }
  },
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    exportedReport: function () {
      const config = {
        filename: 'October-FreeSim' + '-' + this.monthOf,
        sheet: {
          data: [],
        },
      }
      config.sheet.data.push(this.exportReportHeader)
      this.exportedReport.forEach(item => {
        const transaction = [
          {
            value: item.entry,
            type: 'string',
          },
          {
            value: item.action,
            type: 'string',
          },
          {
            value: item.msisdn,
            type: 'string',
          },
          {
            value: item.tarikh,
            type: 'string',
          },
          {
            value: item.dealerId,
            type: 'string',
          },
          {
            value: item.amount,
            type: 'number',
          },
          {
            value: item.plan,
            type: 'string',
          },
        ]
        config.sheet.data.push(transaction)
      })

      zipcelx(config)
      this.enableExport = true
    },
    monthOf: function (val) {
      this.getErechargeRecords(val)
    },
    pagination: {
      handler () {
        this.getErechargeRecords(this.monthOf)
      },
      deep: true,
    },
    search: function (val) {
      clearTimeout(this.searchId)
      this.cancelRequest()
      if (val) {
        this.searchId = setTimeout(() => {
          this.searchEntity = this.search
        }, 1000)
      } else {
        this.searchEntity = ''
      }
    },
    searchEntity: function () {
      this.getErechargeRecords(this.monthOf)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    cancelRequest: function () {
      this.loading = false
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },
    exportReport: function () {
      this.enableExport = false
      this.getErechargeTransactions(this.monthOf, true).then(data => {
        this.exportedReport = data.items
      })
    },
    getErechargeRecords: function (month) {
      this.getErechargeTransactions(month).then(data => {
        this.items = data.items
        this.totalItems = data.totalItems
      })
    },
    async getErechargeTransactions (monthOf, report = false) {
      if (!this.pagination) {
        return { items: [], totalItems: 0 }
      }
      this.loading = true
      const { page, rowsPerPage } = this.pagination
      const params = createGetParams({
        month: monthOf,
        page,
        rowsPerPage,
        search: this.searchEntity,
      })
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(params, { cancelToken: this.source.token })
        const response = await this.$rest.get('getOctoberCampaignReimbursement.php', params)
        this.error = null
        this.loading = false
        return response.data
      } catch (error) {
        this.error = error.message
        this.loading = false
        return { items: [] }
      }
    },
  },
}
</script>
